import React from "react";

export default function Contactus() {
    return (
        <>
            <h4>ติดต่อเราได้ที่: </h4>
            <p><span>🏠 </span>	225 ถนนสุวรรณศร ตำบลสระแก้ว อำเภอเมืองสระแก้ว จ.สระแก้ว 27000</p>
            <p><span>📍 </span>สระเเก้ว ประเทศไทย</p>
            <p><span>📞 </span>037 241 021</p>
        </>
    );
}